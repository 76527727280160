import { Suspense, FunctionComponent, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import {
  ToastContainer,
  Footer,
  MainHeader,
  LoadingIndicator,
} from 'utils/harmony-ui';
import { SESSION_STATE_NAME, SESSION_VISITOR_ID_KEY } from 'typings/constants';
import { useTestRouteParams } from 'contexts/testRouter';
import { PersonalityTestType } from 'typings/PersonalityTest';
import usePersonalityTestActions from 'hooks/usePersonalityTestActions';
import SuspenseFallbackPage from './SuspenseFallbackPage';

import useQuery from 'hooks/useQuery';
import TrackingService from 'services/TrackingService';
import { nanoid } from 'nanoid';
import { usePersonalityTestContext } from 'contexts/PersonalityTestContext';
import { ApiError, GenericError } from '../../typings/errors';

export const LOADING_TEST_ID = 'loading';

const PageContainer: FunctionComponent<{}> = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { type } = useTestRouteParams();
  let title;

  switch (type) {
    case PersonalityTestType.shortCoach: {
      title = 'common.coachTest';
      break;
    }
    case PersonalityTestType.shortEntrepreneur: {
      title = 'common.entrepreneurTest';
      break;
    }
    default: {
      title = 'common.personalityTest';
    }
  }

  let queryVisitorId = useQuery(SESSION_VISITOR_ID_KEY);
  const { setVisitorId } = usePersonalityTestActions();
  const { visitorId } = usePersonalityTestContext();

  useEffect(() => {
    // if has come from wordpress or another service check query
    const id = queryVisitorId || visitorId || nanoid();
    TrackingService.trackVisitorId(id);
    setVisitorId(id);
  }, [setVisitorId, queryVisitorId, visitorId]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t(title)}</title>
      </Helmet>
      <MainHeader title={t(title)} />
      <Suspense fallback={<LoadingIndicator data-testid={LOADING_TEST_ID} />}>
        <ErrorBoundary
          beforeCapture={(scope) => {
            try {
              const storeState = sessionStorage.getItem(SESSION_STATE_NAME);
              scope.setExtra('state', JSON.parse(storeState || '{}'));
            } catch (error) {
              // if we cant retrieve storeState we move on without it.
            }
          }}
          fallback={({ error, resetError }) => {
            const typedError = error as Error | ApiError | GenericError;
            return (
              <SuspenseFallbackPage
                error={typedError}
                onRecover={() => resetError()}
              />
            );
          }}
        >
          {children}
        </ErrorBoundary>
        <ToastContainer limit={3} />
      </Suspense>
      {type === PersonalityTestType.shortEntrepreneur && <Footer />}
    </>
  );
};

export default PageContainer;
